/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    ul: "ul",
    li: "li",
    img: "img"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Hoy voy a explicar otro de los temas que estoy estudiando, como es ya tradición, ", React.createElement(_components.a, {
    href: "https://litox9.wordpress.com/2013/01/20/montemos-una-jamstorming/"
  }, "las cosas que me gustan luego nunca caen en el examen"), ". Pero yo lo explico porque el PLM me parece una buena idea para producción y estoy pensando como se podría integrar con la Producción P2P, pero eso lo dejaré para otro post. Product Lifecycle Management, como indica su nombre, persigue gestionar todos los aspectos de la vida de un producto, desde su diseño, hasta su reciclaje pasando por su fabricación, venta, etc. PLM, por lo tanto, gestiona la base de datos donde se encuentra toda la información del ciclo de vida del producto y se ayuda para ello de herramientas de control de versiones. Además un buen PLM debe integrar las distintas aplicaciones de diseño, ingeniería, fabricación, marketing, gestión de recursos y encargarse de la gestión de usuarios, que asumirán diferentes roles (diseñador, publicista, técnico, operario), tendrán distintos permisos y distintas vistas de la información."), "\n", React.createElement(_components.p, null, "Una de las ventajas del PLM, ya que acumula toda la información de la fabricación, es que podemos conectar a esta aplicación una fábrica automatizada mediante máquinas de CNC/DNC y robots. Por lo tanto PLM es una capa superior que gestiona:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "La interconexión entre varias aplicaciones."), "\n", React.createElement(_components.li, null, "La interconexión entre varios usuarios."), "\n", React.createElement(_components.li, null, "Seguimiento exhaustivo de versiones, actualización y alerta de los cambios efectuados."), "\n"), "\n", React.createElement(_components.p, null, "A continuación he realizado un pequeño esquema de cómo funcionan este tipo de aplicaciones:"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://litox9.files.wordpress.com/2013/01/plm1.png"
  }, React.createElement(_components.img, {
    src: "http://litox9.files.wordpress.com/2013/01/plm1.png?w=950",
    alt: "PLM"
  }))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
